/* You can add global styles to this file, and also import other style files */
html {
  width: 100%;
  height: 100%;

  body {
    width: 100%;
    height: 100%;
  }
}

.panelSelect {
  margin-top: 1.8rem !important;
  margin-left: 0.4rem !important;
}

.selfserviceSelect {
  position: relative;
  top: 1.4em;
  left: .4em;
  //margin-top: 1.8rem !important;
  //margin-left: 0.4rem !important;
}

.error-format {
  display: inline;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
  min-width: 425px;
  max-width: 676px;
  white-space: nowrap;
  background: #FFFFFF;
  color: #952D1F;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 600;
  font-stretch: normal;
  font-size: 14px;
  line-height: 22px;
  font-family: "open sans";
}

.table-container {
  width: 90%;
  margin: auto;
  padding-bottom: 1rem;
}

.img-txt-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    width: 12%;
    img {
      width: 100%;
      height: auto;
      max-width: 60px;
    }
  }

  .heading {
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.25;
    margin: 0 1rem !important;
  }
}

.page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding: 2rem 4rem;
  background: #F0F0F0;
  .img-txt-title {
    display: flex;
    flex-direction: row;
    img {
      padding-bottom: 0.5rem;
      transform: scale(1.5);
    }
    h1 {
      margin-left: 1rem !important;
      margin-top: 0.5rem !important;
    }
  }
}

.subheading-2 {
  font: 500 25px/1.25 Montserrat, sans-serif !important;
  letter-spacing: normal !important;
  margin: 0 0 16px !important;
}

@media (max-width: 992px) {
  .img-txt-container {
    div {
      display: none;
    }
    .heading {
      margin: 0;
      font-size: 2.5vw !important;
      padding: 0.5rem 0;
    }
  }

  .page-header {
    width: 100%;
    padding: 1rem;
    justify-content: center;
    flex-direction: column;
  }

  .img-txt-title h1 {
    font-size: 8vw !important;
  }

  .page-header h4 {
    font-size: 3vw !important;
    margin-left: -1rem !important;
  }

  .myhub-theme .mat-button-base.mat-primary {
    min-width: 0 !important;
    padding: 4px !important;
  }

  .myhub-theme .mat-mdc-tab-body-content {
    padding: 15px 0 !important;
  }

  .myhub-theme .mat-mdc-table .mat-sort-header-content {
    margin-right: 10px !important;
  }

  .mat-raised-button {
    line-height: normal !important;
  }

  .mat-stroked-button {
    line-height: 1.3em !important;
  }

  .mat-typography h5 {
    overflow-wrap: anywhere !important;
  }

  .myhub-theme .loan-def-container .mat-mdc-button-base.mat-primary {
    font-size: 12px !important;
    font-weight: 400 !important;
    padding: 4px !important;
    min-width: 0;
    width: 80% !important;
  }

  .myhub-theme .enr-verf-container .mat-mdc-button-base.mat-primary {
    font-size: 12px !important;
    font-weight: 400 !important;
    padding: 4px !important;
    min-width: 0;
    width: 80% !important;
  }
}
